.body1StyleInputStyle {
  border: 0px;
  font-size: 0.28rem;
  width: 80%;
  line-height: 0.8rem;
}
.pc_in {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.pc_in .divbox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 1rem;
  overflow: hidden;
}
.pc_in .divbox span {
  width: 1rem;
  height: 100%;
  border-radius: 0.04rem;
  border: 1px solid #DDDDDD;
}
.pc_in .divbox span.bl {
  border-color: #90C4FF;
}
.pc_in p {
  position: absolute;
  width: calc(100% + 1.5rem);
  height: 1rem;
  line-height: 1rem;
  top: 0;
  left: 0;
  background: none;
  overflow: hidden;
}
.pc_in p input {
  color: #90C4FF;
  height: 1rem;
  line-height: 1rem;
  letter-spacing: 0.8rem;
  background: none;
  text-indent: 0.3rem;
  font-size: 0.64rem;
  display: block;
  width: 100%;
}
.layoutBox {
  min-height: calc(100vh - 1.76rem);
  padding: 0.3rem 0.3rem 0.5rem;
}
.comBtnStyle {
  color: #ffffff;
  font-size: 0.32rem;
  text-align: center;
  height: 0.88rem;
  line-height: 0.88rem;
  background: #90c4ff;
  border-radius: 0.12rem;
}
.securityCheck {
  font-size: 0.28rem;
}
.securityCheck .caption {
  font-size: 0.32rem;
  font-weight: bold;
  line-height: 0.45rem;
  margin-bottom: 0.25rem;
}
.securityCheck .phone {
  margin-bottom: 0.5rem;
}
.securityCheck .verifyCode {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0.5rem;
}
.securityCheck .verifyCode .inp {
  font-size: 0.28rem;
  display: block;
  width: 100%;
  flex: 1;
  height: 0.6rem;
  border: 0;
  border-bottom: 1px solid #C8C7CC;
}
.securityCheck .verifyCode .btn {
  width: 1.1rem;
  height: 0.52rem;
  text-align: center;
  line-height: 0.52rem;
  border-radius: 0.12rem;
  border: 1px solid #90C4FF;
  margin-left: 0.25rem;
}
