.list-icon-item {
  width: 25%;
  color: #666666;
  font-size: 0.28rem;
  text-align: center;
  margin-bottom: 0.25rem;
}
.list-icon-item .icon {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 auto;
  margin-bottom: 0.08rem;
}
.list-icon-item .icon img {
  display: block;
  width: 100%;
}
