





































.list-icon-item {
	width: 25%;
	color: #666666;
	font-size: .28rem;
	text-align: center;
	margin-bottom: .25rem;
	.icon {
		width: .6rem;
		height: .6rem;
		margin: 0 auto;
		margin-bottom: .08rem;
		img {
			display: block;
			width: 100%;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;