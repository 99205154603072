.titleTwo {
  background: #fff;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  position: relative;
}
.titleTwo p {
  position: relative;
  z-index: 99;
}
.titleTwo .return {
  height: 1rem;
  width: 1rem;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}
.titleTwo .return img {
  display: inline-block;
  width: 0.27rem;
}
.titleTwo .rightIco {
  height: 1rem;
  width: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}
.titleTwo .wordBold {
  font-weight: bold;
}
.titleTwo.back {
  background: none;
  z-index: 11;
}
.titleTwo.back p {
  color: #fff;
}
