.aboutOurs {
  font-family: PingFangSC-Regular;
}
.aboutOurs .contact {
  height: calc(100vh - 55px);
  padding: 0.4rem 0.3rem;
  background-color: #FFFFFF;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
}
.aboutOurs .contact .tital {
  font-family: PingFang SC;
  font-size: 0.28rem;
  color: #333;
  font-weight: bold;
}
.aboutOurs .contact .message {
  color: #333333;
  font-size: 14px;
  line-height: 0.6rem;
}
.layoutBox {
  margin-top: 0.2rem;
  background-color: #F8F8F8;
}
