.comlist {
  padding: 0.2rem 0.2rem;
  line-height: 0.45rem;
  display: flex;
  justify-content: space-between;
}
.comlist .icon {
  width: 0.44rem;
  height: 0.44rem;
  margin-right: 0.12rem;
}
.comlist .icon img {
  width: 100%;
  height: 100%;
  display: block;
}
.comlist .label {
  color: #333333;
  font-size: 0.28rem;
  display: flex;
  align-items: center;
}
.comlist .icon_arrR {
  width: 0.2rem;
  height: 0.2rem;
  border-top: 0.04rem solid #999;
  border-right: 0.04rem solid #999;
}
