



















































































































































































.inputSpan {
  font-size: 0.2rem;
  color: #FF4B04;
}
.endList {
  margin-top: 0.7rem;
}
.btnDivStyle {
  margin-top: 1.7rem;
  margin-bottom: 1rem;
  text-align: center;
}
.btnStyle {
  border: 0px;
  border-radius: 5px;
  margin: 0.3rem;
  // width: 3rem;
  background: #FF4B04;
}

.bodyStyle {
  background: #f1f1f1;
  height: 100%;
}

.vanCellStyle1 {
  margin-left: 0rem;
  margin-right: 0rem;
  border-radius: 5px;
  margin-top: 0rem;
  /* background: #f1f1f1; */
}

.vanCellStyle2 {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  border-radius: 5px;
  margin-top: 0.3rem;
  /* background: #f1f1f1; */
}

.van-icon-arrow-left {
  color: #ffffff;
}

.van-hairline--bottom {
  background: #ecbb34;
  color: #ffffff;
}

.van-nav-bar__title {
  background: #ecbb34;
  color: #ffffff;
}

/* .van-ellipsis {
  background: 3d8051;
} */

.ulList {
  padding: 0.3rem;
  background: #ffffff;
  border-radius: 5px;
  // border-top: 0px;
}
.inputStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}
.inputStyle2 {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
  float: left;
}

.posRight {
  border: none;
  height: 0.6rem;
  padding: 0 0.26rem;
  border-radius: 0.8rem;
  color: #ffffff;
  font-size: 0.24rem;
  line-height: 0.6rem;
  background-color: #5867f0;
  float: right;
}

.whole {
  background: #ffffff;
}
.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}
.bottomBtnStyle {
  width: 100%;
  background: #e1c364;
  border-radius: 5px;
  border: 0px;
}
.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}
.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;