


























































.aboutOurs {
	font-family: PingFangSC-Regular;
	.contact {
		height: calc(100vh - 55px);
		padding: 0.4rem 0.3rem;
		background-color: #FFFFFF;
		border-top-left-radius: 0.32rem;
		border-top-right-radius: 0.32rem;
		.tital {
			font-family: PingFang SC;
			font-size: 0.28rem;
			color: #333;
			font-weight: bold;
		}
		.message {
			// margin-top: 0.3rem;
			color: #333333;
			font-size: 14px;
			// text-indent:28px;
			line-height: 0.6rem;
		}
	}
}

// --------20200411[start]------
.layoutBox {
	margin-top: 0.2rem;
	// padding-top: 0.88rem;
	background-color: #F8F8F8;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;