.body1StyleInputStyle {
  border: 0px;
  font-size: 0.28rem;
  width: 6.7rem;
  line-height: 0.8rem;
}
.pc_in {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 0.22rem;
}
.pc_in .divbox {
  display: flex;
  width: 100%;
  height: 1rem;
  overflow: hidden;
}
.pc_in .divbox span {
  width: 1.4rem;
  height: 100%;
  border-radius: 0.04rem;
  border: 1px solid #E5E5E5;
}
.pc_in .divbox span:first-child {
  border-top-left-radius: 0.16rem;
  border-bottom-left-radius: 0.16rem;
}
.pc_in .divbox span:last-child {
  border-top-right-radius: 0.16rem;
  border-bottom-right-radius: 0.16rem;
}
.pc_in .divbox span.bl {
  border-color: #5569FC;
  color: #5569FC;
  line-height: 1rem;
  text-indent: 0.3rem;
  font-size: 0.64rem;
}
.pc_in p {
  position: absolute;
  width: 100%;
  height: 1rem;
  line-height: 1rem;
  top: 0;
  left: 0;
  background: none;
  overflow: hidden;
}
.pc_in p input {
  color: #5569FC;
  height: 1rem;
  line-height: 1rem;
  letter-spacing: 0.75rem;
  background: none;
  font-size: 0.64rem;
  display: block;
  width: 100%;
  text-indent: -1em;
}
.layoutBox {
  min-height: calc(100vh - 54px);
  margin-top: 0.2rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  font-family: PingFangSC-Regular;
}
.comBtnStyle {
  margin: 0 0.2rem;
  color: #FFFFFF;
  font-size: 0.32rem;
  text-align: center;
  height: 0.9rem;
  line-height: 0.9rem;
  background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
  border-radius: 0.16rem;
}
.securityCheck {
  padding: 0.3rem 0;
  margin: 0 0.4rem;
  font-size: 0.28rem;
}
.securityCheck .top {
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  font-size: 0.28rem;
  color: #333333;
}
.securityCheck .top .phone {
  margin-left: 0.2rem;
}
.securityCheck .verifyCode {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0.64rem;
  border-bottom: 0.5px solid rgba(211, 217, 222, 0.6);
}
.securityCheck .verifyCode .inp {
  color: #999999;
  font-size: 0.24rem;
  display: block;
  width: 100%;
  flex: 1;
  padding: 10px 0;
  border: 0;
}
.securityCheck .verifyCode .btn {
  padding: 0.2rem 0;
  font-size: 0.24rem;
  margin-left: 0.25rem;
}
.securityCheck .c_title {
  color: #333333;
  font-size: 0.28rem;
  font-weight: bold;
}
