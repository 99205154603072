









































































.btnFdivStyle {
  text-align: center;
}
.btnStyle {
  margin-top: 1rem;
  width: 4.2rem;
}

.inputStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}

.fontStyle {
  font-size: 0.1rem;
  color: #9b9b9b;
  margin: 0.3rem;
}

.iconStyle {
  margin-right: 0.15rem;
}

.ulList > li {
  padding: 0.3rem;
  margin: 0.3rem;
  background: #ffffff;
  border-radius: 5px;
}

.listStyle {
  background: #ffffff;
  border-radius: 5px;
}

.roleBtnStyle {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 5px;
}

.tpuschool {
  background: #f1f1f3;
  height: 100%;
  width: 100%;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;