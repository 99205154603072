.van-field__control {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 0;
  color: #373737;
  text-align: right;
  background-color: transparent;
  border: 0;
  resize: none;
}
.middedStyle {
  color: #a5a5a5;
  background: #f1f1f1;
  font-size: 0.2rem;
  height: 0.8rem;
  padding: 0.15rem;
}
.btnFdivStyle {
  text-align: center;
}
.btnStyle {
  margin-top: 1rem;
  width: 4.2rem;
}
.c_field {
  font-size: 14px;
  padding: 0.2rem 16px;
}
.inputStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}
.btnOp {
  margin-top: 0rem;
  margin-bottom: 0.1rem;
  text-align: center;
}
.fontStyle {
  font-size: 0.1rem;
  color: #9b9b9b;
  margin: 0.3rem;
}
.iconStyle {
  margin-right: 0.15rem;
}
.ulList > li {
  padding: 0.3rem;
  margin: 0.3rem;
  background: #ffffff;
  border-radius: 5px;
}
.listStyle {
  background: #ffffff;
  border-radius: 5px;
}
.roleBtnStyle {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 5px;
}
.tpuschool {
  background: #f1f1f3;
  height: 100%;
  width: 100%;
  margin-bottom: 2rem;
}
.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}
.bottomBtnStyle {
  width: 100%;
  background: #e1c364;
  border-radius: 5px;
  border: 0px;
}
.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}
.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}
.layoutBox {
  padding: 0.2rem;
  min-height: 93vh;
  font-family: PingFangSC-Regular;
}
.robotBox {
  background: white;
  border-radius: 0.12rem;
  margin-bottom: 0.2rem;
  overflow: hidden;
}
.robotBox .van-radio--horizontal {
  margin: 0 0 0 0.4rem;
}
.list-cell {
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.2rem;
}
.list-cell .left {
  color: #6F7A8B;
  display: flex;
  align-items: center;
}
.list-cell .icon {
  margin-right: 0.2rem;
}
.list-cell .icon img {
  width: 0.44rem;
  display: block;
}
.list-cell .icon1 {
  margin-left: 0.1rem;
}
.list-cell .icon1 img {
  width: 0.355rem;
  display: block;
}
.list-cell .right {
  display: flex;
  align-items: center;
  color: #5569FC;
}
.list-cell .right .qpxl {
  margin-left: 0.14rem;
  width: 0.18rem;
  height: 0.1rem;
}
.list-cell .right1 {
  display: flex;
  align-items: center;
  color: #5569FC;
}
.list-cell .right2 {
  color: #333333;
}
.list-cell .inp {
  height: 0.48rem;
  border: 0;
  width: 2rem;
  text-align: right;
}
.list-cell .inp1 {
  height: 0.48rem;
  border: 0;
  width: 2rem;
  text-align: right;
  background-color: #F8F8F8;
}
.list-cell1 {
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.25rem;
}
.list-cell1 .left {
  color: #666666;
  display: flex;
  align-items: center;
}
.list-cell1 .icon {
  margin-right: 0.1rem;
}
.list-cell1 .icon img {
  width: 0.48rem;
  display: block;
}
.list-cell1 .right {
  color: #5569FC;
}
.list-cell1 .inp {
  height: 0.48rem;
  border: 0;
  width: 2rem;
  text-align: right;
}
.list-cell1 .inp1 {
  height: 0.48rem;
  border: 0;
  width: 2rem;
  text-align: right;
  background-color: #F8F8F8;
}
.robotBtns {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.robotBtns .btn {
  font-size: 0.32rem;
  color: #FFFFFF;
  line-height: 0.8rem;
  width: 7.02rem;
  height: 0.8rem;
  text-align: center;
  background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
  border-radius: 0.08rem;
}
.tan {
  padding: 0.2rem 0;
}
.tan .quedin {
  padding: 0.3rem 1rem;
  background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
  text-align: center;
  color: #FFFFFF;
  border-radius: 6px;
  margin-top: 0.3rem;
}
.list_item0 {
  position: fixed;
  z-index: 999;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0;
  width: 90%;
  background-color: #f8f8f8;
  border-bottom: 1px solid #EDEDED;
}
.list_item0 .left .time {
  color: #666666;
  font-size: 0.32rem;
  font-weight: bold;
  align-items: center;
}
.list_item0 .icon {
  margin-right: 0.1rem;
}
.list_item0 .icon img {
  width: 0.48rem;
  display: block;
}
.list_item0 .right {
  color: #666666;
  font-size: 0.32rem;
  font-weight: bold;
  align-items: center;
}
.tan1 {
  padding: 0.2rem 0;
  padding-top: 1.26rem;
  padding-bottom: 1.2rem;
}
.tan1 .list_item {
  font-size: 0.28rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0.25rem;
  border-bottom: 1px solid #EDEDED;
}
.tan1 .list_item .item_left {
  display: flex;
  align-items: center;
}
.tan1 .list_item .item_left .left {
  color: #666666;
  display: flex;
  align-items: center;
}
.tan1 .list_item .item_left .icon {
  margin-right: 0.1rem;
}
.tan1 .list_item .item_left .icon img {
  width: 0.48rem;
  display: block;
}
.tan1 .list_item .item_left .right {
  color: #5569FC;
}
.tan1 .list_item .item_left .inp1 {
  font-weight: bold;
  height: 0.48rem;
  border: 0;
  width: 1rem;
  text-align: right;
  background-color: #F8F8F8;
}
.tan1 .list_item .item_right {
  display: flex;
  align-items: center;
}
.tan1 .list_item .item_right .left {
  color: #666666;
  display: flex;
  align-items: center;
}
.tan1 .list_item .item_right .icon {
  margin-right: 0.1rem;
}
.tan1 .list_item .item_right .icon img {
  width: 0.48rem;
  display: block;
}
.tan1 .list_item .item_right .right {
  color: #5569FC;
}
.tan1 .list_item .item_right .inp1 {
  font-weight: bold;
  height: 0.48rem;
  border: 0;
  width: 0.7rem;
  text-align: right;
  background-color: #F8F8F8;
}
.tan1 .quedin1 {
  position: fixed;
  bottom: 0.1rem;
  z-index: 999;
  padding: 0.3rem 3.12rem;
  background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
  text-align: center;
  color: #FFFFFF;
  border-radius: 6px;
}
.xiala {
  margin-left: 0.04rem;
}
.xiala img {
  width: 0.22rem;
  height: 0.14rem;
}
.icon_arrR {
  width: 0.2rem;
  height: 0.2rem;
}
.mid_list-cell {
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.25rem;
}
.mid_list-cell .left {
  margin-left: 0.56rem;
  color: #666666;
  display: flex;
  align-items: center;
}
.mid_list-cell .icon {
  margin-right: 0.1rem;
}
.mid_list-cell .icon img {
  width: 0.48rem;
  display: block;
}
.mid_list-cell .right {
  color: #5569FC;
  display: flex;
  align-items: center;
}
.mid_list-cell .right img {
  margin-left: 0.14rem;
  width: 0.18rem;
  height: 0.1rem;
}
.mid_list-cell .inp {
  height: 0.48rem;
  border: 0;
  width: 2rem;
  text-align: right;
}
.mid_list-cell .inp1 {
  height: 0.48rem;
  border: 0;
  width: 2rem;
  text-align: right;
  background-color: #F8F8F8;
}
