

























































































































































































































































































































.layoutBox {
	min-height: calc(100vh - 1.76rem);
	// padding: 0.88rem 0;
}
.headTab {
	color: #666666;
	font-size: 0.32rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 0.88rem;
	margin: 0 0.3rem;
	.active {
		color: #373737;
		font-size: 0.36rem;
		font-weight: bold;
	}
	.icon {
		width: .4rem;
		height: .4rem;
		img {
			width: 100%;
			display: block;
		}
	}
}
.classTab {
	color: #666666;
	font-size: 0.32rem;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 0.88rem;
	line-height: 0.88rem;
	border-bottom: 1px solid #ededed;
	margin: 0 0.3rem;
	.active {
		color: #90c4ff;
		border-bottom: 1px solid #90c4ff;
	}
}
.tabInfo {
	padding: 0.25rem 0.3rem;
	border-bottom: 0.12rem solid #f5f5f5;
	.caption {
		font-size: 0.32rem;
		height: 0.45rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.25rem;
		.yen {
			font-size: 0.24rem;
			text-transform: uppercase;
		}
	}
	.labelCheck {
		font-size: 0.28rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 0.25rem;
		.agreelaw {
			display: flex;
			align-items: center;
			.icon {
				width: 0.32rem;
				height: 0.32rem;
				display: block;
			}
		}
		.right {
			font-size: 0.24rem;
		}
	}
}
.radio-list-group {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 0.35rem;
	.radio-list-item {
		color: #90c4ff;
		font-size: 0.24rem;
		width: 3.3rem;
		height: 1.52rem;
		border: 1px solid #90c4ff;
		border-radius: 0.12rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-bottom: 0.25rem;
		.yen {
			font-size: 0.4rem;
		}
		&.active {
			color: #ffffff;
			background: #90c4ff;
		}
	}
}
.noteInfo {
	padding: 0.25rem 0.3rem;
	border-bottom: 0.12rem solid #f5f5f5;
	.caption {
		font-size: 0.32rem;
		margin-bottom: 0.25rem;
	}
}
.note-list {
	display: flex;
	align-items: center;
	margin-bottom: 0.25rem;
	&:last-child {
		margin-bottom: 0.15rem;
	}
	.icon {
		width: 0.4rem;
		height: 0.4rem;
		margin-right: 0.25rem;
		img {
			display: block;
			width: 100%;
		}
	}
	.text {
		flex: 1;
		color: #666666;
		font-size: 0.24rem;
		line-height: 0.35rem;
		.title {
			color: #373737;
			font-size: 0.28rem;
			line-height: 0.4rem;
			margin-bottom: 0.1rem;
		}
	}
}
.adPoster {
	height: 1.64rem;
	margin: 0.25rem 0.3rem;
	img {
		width: 100%;
		height: 100%;
		display: block;
	}
}
.posterLayout {
	position: fixed;
	z-index: 98;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(4, 4, 15, 0.4);
}
.posterCode {
	position: fixed;
	z-index: 99;
	left: .3rem;
	right: .3rem;
	top: 50%;
	background-color: white;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	.poster {
		position: relative;
		img {
			display: block;
			width: 100%;
		}
		.code {
			position: absolute;
			left: 50%;
			top: 2.9rem;
			width: 1.8rem;
			height: 1.8rem;
			margin-left: -.9rem;
		}
	}
	.p_buttom {
		display: flex;
		justify-content: space-between;
	}
	.shi {
		flex: 1;
		height: .8rem;
		line-height: .8rem;
		text-align: center;
		// background: url(../../assets/imgs/share/icon_shut.png) no-repeat center center;
		border: 0.01rem solid black;
		background-size: .6rem .6rem;
		// margin-left: 0.5rem;
	}
	.fou {
		flex: 1;
		height: .8rem;
		line-height: .8rem;
		text-align: center;
		// background: url(../../assets/imgs/share/icon_shut.png) no-repeat center center;
		border: 0.01rem solid black;
		background-size: .6rem .6rem;
		// margin-left: 0.5rem;
	}
}
.wenzi {
	padding:0.5rem 0;
	// margin: 0 auto;
	text-align: center;
	font-size: 0.31rem;
	font-weight: bold;
	color: black;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;