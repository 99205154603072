.btnFdivStyle {
  text-align: center;
}
.btnStyle {
  margin-top: 1rem;
  width: 4.2rem;
}
.c_field {
  font-size: 14px;
  padding: 0.2rem;
}
.inputStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}
.btnOp {
  margin-top: 0rem;
  margin-bottom: 0.1rem;
  text-align: center;
}
.fontStyle {
  font-size: 0.1rem;
  color: #9b9b9b;
  margin: 0.3rem;
}
.iconStyle {
  margin-right: 0.15rem;
}
.btnOpStyle {
  border: 0px;
  border-radius: 5px;
  margin: 0.1rem;
  width: 90%;
  background: #0a903b;
}
.ulList > li {
  padding: 0.3rem;
  margin: 0.3rem;
  background: #ffffff;
  border-radius: 5px;
}
.listStyle {
  background: #ffffff;
  border-radius: 5px;
}
.roleBtnStyle {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 5px;
}
.layoutBox {
  padding: 0.1rem 0.3rem;
  min-height: 100vh;
}
.robotBox {
  background: white;
  border-radius: 0.12rem;
  margin-bottom: 0.1rem;
  overflow: hidden;
}
.robotBox .van-radio--horizontal {
  margin: 0 0 0 0.4rem;
}
.list-cell {
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.25rem;
  border-bottom: 1px solid #EDEDED;
}
.list-cell .left {
  color: #666666;
  display: flex;
  align-items: center;
}
.list-cell .icon {
  margin-right: 0.1rem;
}
.list-cell .icon img {
  width: 0.48rem;
  display: block;
}
.list-cell .right {
  color: #373737;
}
.list-cell .inp {
  height: 0.48rem;
  border: 0;
  width: 2rem;
  text-align: right;
}
.robotBtns {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}
.robotBtns .btn {
  font-size: 0.32rem;
  color: #FFFFFF;
  line-height: 0.88rem;
  width: 3.2rem;
  height: 0.88rem;
  text-align: center;
  background: #90C4FF;
  border-radius: 0.12rem;
}
