.aboutOurs .contact {
  padding: 0.3rem 0;
}
.aboutOurs .contact .ico {
  padding: 1rem 0;
  font-size: 0.24rem;
  text-align: center;
}
.aboutOurs .contact .ico img {
  display: block;
  width: 2.67rem;
  margin: auto;
}
.aboutOurs .contact .contact-item {
  padding: 0.2rem 0;
  border-bottom: 0.5px solid rgba(211, 217, 222, 0.5);
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aboutOurs .contact .contact-item .icon {
  width: 0.16rem;
  height: 0.28rem;
  display: block;
}
.aboutOurs .contact .contact-item .label {
  color: #333333;
  font-size: 0.28rem;
}
.layoutBox {
  height: 100vh;
  padding: 0.25rem 0.3rem;
  font-family: PingFangSC-Regular;
}
