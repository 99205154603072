














































.titleTwo {
    background: #fff;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    position: relative;

    p{
        position: relative;
        z-index: 99;
    }


    .return {
        height: 1rem;
        width: 1rem;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999;

        img {
            display: inline-block;
            width: 0.27rem;
        }
    }
    .rightIco{
        height: 1rem;
        width: 1rem;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;

    }

    .wordBold{
        font-weight: bold;
    }

}
.titleTwo.back {
    background: none;
    z-index: 11;
    p{
        color: #fff;
    }
}


@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;