








































































































.layoutBox {
	padding: 0.3rem;
	min-height: 100vh;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;