.addwaltshow_box2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.1rem 0.1rem 0 0;
  border: none;
}
.addwaltshow_box2 .box_makewalt {
  height: 1.1rem;
  color: #5056fd;
  font-size: 0.32rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.addwaltshow_box2 .box_makewalt img {
  width: 24px;
  height: 24px;
  margin-left: 0.4rem;
}
.addwaltshow_box2 .box_makewalt span {
  margin-left: 0.2rem;
}
.addwaltshow_box2 .box_addwalt {
  border-bottom: none;
}
.addwaltshow_box2 .line {
  height: 0.1rem;
  background: #f6f6f8;
}
.addwaltshow_box2 .quxiao {
  border-bottom: none;
  color: #999999;
}
.chongzhi {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.chongzhi .chongzhi_box {
  width: 6.7rem;
  background: #f2f2fe;
  margin: 0 auto;
  margin-top: 0.4rem;
  display: flex;
  border-radius: 5px;
  height: 0.82rem;
  align-items: center;
  justify-content: space-between;
}
.chongzhi .chongzhi_box span {
  margin-left: 0.3rem;
}
.chongzhi .chongzhi_box .chonzghi_right {
  margin-right: 0.3rem;
  font-size: 0.28rem;
}
.chongzhi /deep/ #qrcode {
  margin: 0 auto;
  margin-top: 1rem;
  width: 2.8rem;
  height: 2.8rem;
}
.chongzhi /deep/ #qrcode img {
  padding: 5px;
  background: #eceaea;
  width: 2.8rem;
  height: 2.8rem;
}
.chongzhi .dizhi {
  margin: 0 auto;
  margin-top: 1.8rem;
  font-size: 0.3rem;
}
.chongzhi .fuzhi_address {
  display: flex;
  margin-top: 0.5rem;
  font-size: 0.24rem;
  margin-left: 0.4rem;
  align-items: center;
}
.chongzhi .fuzhi_address img {
  width: 0.3rem;
  height: 0.3rem;
  margin-left: 0.3rem;
}
.chongzhi .zuixiao {
  margin: 0 auto;
  margin-top: 1.4rem;
  font-size: 0.28rem;
}
