






































































































.bill-list-item {
	color: #666666;
	font-size: .28rem;
	// background: #F5F5F5;
	background: #FFFFFF;
	border-radius: 0.16rem;
	margin-bottom: 0.25rem;
	.hd {
		margin: 0 .2rem;
		border-bottom: 0.5px solid #D3D9DE;
		.caption {
			padding: 0.2rem 0;
			// height: .4rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.left {
				display: flex;
				align-items: center;
				.yen {
					color: #333333;
					font-size: 0.32rem;
					margin-right: 0.28rem;
				}
				.type {
					font-size: .24rem;
					transform: scale(0.83);
				}
				.active {
					padding: 0.03rem 0.08rem;
					background-color: rgba(50, 191, 136, 0.1);
					color: #32BF88;
					border-radius: 0.04rem;
				}
				.active1 {
					padding: 0.03rem 0.08rem;
					background-color: rgba(245, 70, 93, 0.1);
					color: #F5465D;
					border-radius: 0.04rem;
				}
			}
			.date {
				color: #666666;
				font-size: .24rem;
			}
			// margin-bottom: .25rem;
		}
		.specialFont {
			color: #666666;
			font-size: 0.24rem;
			text-transform: uppercase;
		}
		
	}
	.bd {
		// color: #373737;
		// padding: .5rem .2rem  0;
		padding: 0.21rem 0.85rem;
		border-top: 1px solid #F5F5F5;
		.msg {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.2rem;
			text-align: left;
		}
		.item1{
			width: 60%;
			.vlu {
				color: #333333;
				font-size: 0.28rem;
				margin-bottom: 0.02rem;
				font-weight: bold;
			}
			.name {
				color: #666666;
				font-size: 0.24rem;
				// font-weight: bold;
			}
		}
		.item2{
			width: 32%;
			.vlu {
				color: #333333;
				font-size: 0.28rem;
				margin-bottom: 0.02rem;
				font-weight: bold;
			}
			.name {
				color: #666666;
				font-size: 0.24rem;
				// font-weight: bold;
			}
		}
	}
	.fd {
		height: .5rem;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		&::before {
			content: '';
			position: absolute;
			z-index: 1;
			left: 0;
			right: 0;
			top: 50%;
			border-top: 1px solid #F5F5F5;
		}
		img {
			width: .24rem;
			height: .24rem;
			display: block;
			// background: #F5F5F5;
			background: #FFFFFF;
			position: relative;
			z-index: 2;
			&.up {
				-webkit-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}
	}
	
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;