






























































.layoutBox {
	padding: 0.3rem;
	min-height: calc(100vh - 1.76rem);
}
.article {
	color: #666666;
	font-size: .24rem;
	line-height: 1.5;
	h4 {
		color: #373737;
		font-size: .28rem;
		margin-bottom: .2rem;
	}
	h5 {
		color: #373737;
		font-size: .24rem;
		margin-bottom: .1rem;
	}
	p {
		margin-bottom: .1rem;
	}
	div {
		margin-bottom: .2rem;
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;