


























































.layoutBox {
	font-family: PingFangSC-Regular;
	// margin: 0 .2rem;
	padding: 0 10px;
	margin-top: 0.2rem;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	min-height: 91.8vh;
	padding-top: 0.2rem;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;