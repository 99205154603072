.log-list-item {
  color: #666666;
  font-size: 0.28rem;
  background: #FFFFFF;
  border-radius: 0.16rem;
  margin-bottom: 0.25rem;
}
.log-list-item .hd {
  margin: 0 0.2rem;
  border-bottom: 0.5px solid #D3D9DE;
}
.log-list-item .hd .caption {
  padding: 0.2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.log-list-item .hd .caption .yen {
  color: #333333;
  font-size: 0.32rem;
  margin-right: 0.25rem;
}
.log-list-item .hd .caption .type {
  font-size: 0.24rem;
}
.log-list-item .hd .specialFont {
  color: #666666;
  font-size: 0.24rem;
  text-transform: uppercase;
}
.log-list-item .bd {
  padding: 0.1rem 0;
  margin: 0 0.2rem;
}
.log-list-item .bd .info {
  padding: 0.1rem 0;
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  color: #666666;
}
.log-list-item .bd .info .specialFont {
  margin-left: 0.2rem;
  color: #333333;
}
.log-list-item .bd .info1 {
  padding: 0.1rem 0;
  display: flex;
  font-size: 0.28rem;
}
.log-list-item .bd .info1 .i_left {
  width: 17%;
  color: #666666;
}
.log-list-item .bd .info1 .specialFont1 {
  width: 83%;
  color: #333333;
}
.log-list-item .bd .remarks {
  flex: 1;
  color: #373737;
}
.log-list-item .fd {
  height: 0.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.log-list-item .fd::before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  border-top: 1px solid #D8D8D8;
}
.log-list-item .fd img {
  width: 0.24rem;
  height: 0.24rem;
  display: block;
  background: #F5F5F5;
  position: relative;
  z-index: 2;
}
.log-list-item .fd img.up {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
