.bill-list-item {
  color: #666666;
  font-size: 0.28rem;
  background: #FFFFFF;
  border-radius: 0.16rem;
  margin-bottom: 0.25rem;
}
.bill-list-item .hd {
  margin: 0 0.2rem;
  border-bottom: 0.5px solid #D3D9DE;
}
.bill-list-item .hd .caption {
  padding: 0.2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bill-list-item .hd .caption .left {
  display: flex;
  align-items: center;
}
.bill-list-item .hd .caption .left .yen {
  color: #333333;
  font-size: 0.32rem;
  margin-right: 0.28rem;
}
.bill-list-item .hd .caption .left .type {
  font-size: 0.24rem;
  transform: scale(0.83);
}
.bill-list-item .hd .caption .left .active {
  padding: 0.03rem 0.08rem;
  background-color: rgba(50, 191, 136, 0.1);
  color: #32BF88;
  border-radius: 0.04rem;
}
.bill-list-item .hd .caption .left .active1 {
  padding: 0.03rem 0.08rem;
  background-color: rgba(245, 70, 93, 0.1);
  color: #F5465D;
  border-radius: 0.04rem;
}
.bill-list-item .hd .caption .date {
  color: #666666;
  font-size: 0.24rem;
}
.bill-list-item .hd .specialFont {
  color: #666666;
  font-size: 0.24rem;
  text-transform: uppercase;
}
.bill-list-item .bd {
  padding: 0.21rem 0.85rem;
  border-top: 1px solid #F5F5F5;
}
.bill-list-item .bd .msg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem;
  text-align: left;
}
.bill-list-item .bd .item1 {
  width: 60%;
}
.bill-list-item .bd .item1 .vlu {
  color: #333333;
  font-size: 0.28rem;
  margin-bottom: 0.02rem;
  font-weight: bold;
}
.bill-list-item .bd .item1 .name {
  color: #666666;
  font-size: 0.24rem;
}
.bill-list-item .bd .item2 {
  width: 32%;
}
.bill-list-item .bd .item2 .vlu {
  color: #333333;
  font-size: 0.28rem;
  margin-bottom: 0.02rem;
  font-weight: bold;
}
.bill-list-item .bd .item2 .name {
  color: #666666;
  font-size: 0.24rem;
}
.bill-list-item .fd {
  height: 0.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bill-list-item .fd::before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  border-top: 1px solid #F5F5F5;
}
.bill-list-item .fd img {
  width: 0.24rem;
  height: 0.24rem;
  display: block;
  background: #FFFFFF;
  position: relative;
  z-index: 2;
}
.bill-list-item .fd img.up {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
