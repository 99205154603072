



































.comlist {
	padding: .2rem 0.2rem;
	line-height: .45rem;
	// border-bottom: 1px solid #EDEDED;
	display: flex;
	justify-content: space-between;
	.icon {
		width: 0.44rem;
		height: 0.44rem;
		margin-right: 0.12rem;
		img {
			width: 100%;
			height: 100%;
			display: block;
		}
	}
	.label {
		color: #333333;
		font-size: .28rem;
		display: flex;
		align-items: center;
	}
	.icon_arrR{
		width: 0.2rem;
		height: 0.2rem;
		border-top: 0.04rem solid #999;
		border-right: 0.04rem solid #999;
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;