
















































































































































































































































































































































































































































.btnFdivStyle {
  text-align: center;
}
.btnStyle {
  margin-top: 1rem;
  width: 4.2rem;
}
.c_field {
  font-size: 14px;
  padding: 0.2rem;
}
.inputStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}

.btnOp {
  margin-top: 0rem;
  margin-bottom: 0.1rem;
  text-align: center;
}
.fontStyle {
  font-size: 0.1rem;
  color: #9b9b9b;
  margin: 0.3rem;
}

.iconStyle {
  margin-right: 0.15rem;
}
.btnOpStyle {
  border: 0px;
  border-radius: 5px;
  margin: 0.1rem;
  width: 90%;
  background: #0a903b;
}

.ulList > li {
  padding: 0.3rem;
  margin: 0.3rem;
  background: #ffffff;
  border-radius: 5px;
}

.listStyle {
  background: #ffffff;
  border-radius: 5px;
}

.roleBtnStyle {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 5px;
}

.tpuschool {
  // background: #f1f1f3;
  // height: 100%;
  // width: 100%;
  // margin-bottom: 2rem;
}

// ----------20200410[start]---------
.layoutBox {
	padding: 0.1rem 0.3rem;
	min-height: 100vh;
}
.robotBox {
	background: white;
	border-radius: .12rem;
	margin-bottom: .1rem;
	overflow: hidden;
	.van-radio--horizontal {
		margin: 0 0 0 0.4rem;
	}
}
.list-cell {
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2rem .25rem;
	border-bottom: 1px solid #EDEDED;
	.left {
		color: #666666;
		display: flex;
		align-items: center;
	}
	.icon {
		margin-right: .1rem;
		img {
			width: .48rem;
			display: block;
		}
	}
	.right {
		color: #373737;
	}
	.inp {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
	}
	
}

.robotBtns {
	display: flex;
	justify-content: space-between;
	margin: 1rem 0;
	.btn {
		font-size: .32rem;
		color: #FFFFFF;
		line-height: .88rem;
		width: 3.2rem;
		height: .88rem;
		text-align: center;
		background: #90C4FF;
		border-radius: .12rem;
	}
	
}

// ----------20200410[end]---------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;